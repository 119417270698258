import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './ProjectImage.scss';

const ProjectImage = ({ url, image }) => (
    <a
        className="project-image-container"
        href={url}
        rel="noopener noreferrer"
        target="_blank"
    >
        {image !== null && (
            <GatsbyImage image={getImage(image)} alt="" objectFit="cover" />
        )}

        <div className="button-container">Visit website</div>
    </a>
);

export default ProjectImage;
