import * as React from 'react';
import { graphql, Link } from 'gatsby';
import proptypes from 'proptypes';
import Layout from '../components/Layout';
import Title from '../components/Title';
import SEO from '../components/SEO';
import SubTitle from '../components/SubTitle';
import ProjectImage from '../components/ProjectImage/ProjectImage';

const Article = class {
    constructor(project) {
        this.title = project.title;
        this.url = project.url;
        this.id = project.id;
        this.description = project.body;
        this.quote = project.quote;
        this.image = project.image;
    }

    render() {
        return (
            <article id={this.title} key={this.id}>
                <section>
                    <div className="padded wrapper full-height">
                        <div className="magazine --no-gap">
                            <SubTitle>
                                <h1
                                    className="claim"
                                    dangerouslySetInnerHTML={{
                                        __html: this.title,
                                    }}
                                />
                            </SubTitle>
                            <blockquote>
                                <q
                                    dangerouslySetInnerHTML={{
                                        __html: this.quote,
                                    }}
                                />
                            </blockquote>
                        </div>
                        <ProjectImage image={this.image} url={this.url} />
                        <div>
                            <div
                                className="description"
                                dangerouslySetInnerHTML={{
                                    __html: this.description,
                                }}
                            />
                        </div>
                        <a
                            className="cta-button"
                            href={this.url}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            visit website
                        </a>
                    </div>
                </section>
            </article>
        );
    }
};

const ProjectsPage = ({ data }) => {
    const orderedProjects = data.allProjectsYaml.nodes.sort(
        (a, b) => a.nr - b.nr
    );

    return (
        <Layout>
            <SEO
                title="Projects"
                description="Here are some awesome projects you will love. Feel free to get some inspiration. If this is what other people's projects looks like, image yours!"
            />

            <Title pre="these are some" post="you will love">
                <h1 className="claim">
                    awesome <br />
                    projects
                </h1>
            </Title>
            {orderedProjects.map((project) => {
                const article = new Article(project);
                return article.render();
            })}

            <section>
                <div className="padded wrapper gap-top-lg gap-bottom-lg">
                    <div className="magazine">
                        <SubTitle pre="don't just watch and wait">
                            <h1 className="claim">get featured!</h1>
                        </SubTitle>
                        <div className="description">
                            <p>
                                We are really happy to feature everyone who
                                wants to work with us.
                            </p>
                            <p>
                                It's not just about being featured, really. It's
                                about being out there, displaying the fruits of
                                your hard work. You already made it,
                                <strong>now show it!</strong>
                            </p>
                            <p>
                                What are you waiting for? You are literally just
                                one click away!
                            </p>
                        </div>
                    </div>
                    <Link className="cta-button" to="/contact">
                        contact us
                    </Link>
                </div>
            </section>
        </Layout>
    );
};

export const projects = graphql`
    query ProjectsPageQuery {
        allProjectsYaml {
            nodes {
                body
                url
                id
                nr
                quote
                title
                image {
                    childImageSharp {
                        gatsbyImageData(
                            quality: 90
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                        )
                    }
                }
            }
        }
    }
`;

ProjectsPage.propTypes = {
    data: proptypes.object,
};

export default ProjectsPage;
