import React from 'react';
import proptypes from 'proptypes';

const SubTitle = (props) => (
    <div className="float-left --box">
        {props.pre && <p className="super"> {props.pre} </p>}
        {props.children}
        {props.post && <p className="super">{props.post}</p>}
    </div>
);
export default SubTitle;

SubTitle.propTypes = {
    pre: proptypes.string,
    post: proptypes.string,
    children: proptypes.any,
};
